import React from 'react';
import { AuthProvider } from '../components/context/AuthContext';
import GoogleSignInHandler from '../components/GoogleSignInHandler';
import NoLayout from '../components/layouts/NoLayout';

const Google: React.FC = () => {
    return (
        <NoLayout>
            <AuthProvider>
                <GoogleSignInHandler/>
            </AuthProvider>
        </NoLayout>
    );
};

export default Google;
