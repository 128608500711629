import React from 'react';
import styled, { css, keyframes }  from 'styled-components';
import { background, primary, secondary } from '../util/theme';

const NUMBER_OF_NOTES = 5;

const NOTE_WIDTH = 14;
const NOTE_MARGIN = 3;

const LOADER_PADDING = 32;
const LOADER_HEIGHT = 75;

const ANIM_DELAY = 0.35;
const ANIM_DURATION = 2;

const Container = styled.div`
	width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Note = styled.div`
    position: absolute;
    background: linear-gradient(180deg, ${primary} 0%, ${secondary} 100%);
    width: ${NOTE_WIDTH}px;
    height: ${LOADER_HEIGHT}px;
    animation-timing-function: ease-out;
    border-radius: 12px;
    top: 100%;
`;

const notes = () => {
    let styles = '';

    for (let i = 1; i <= NUMBER_OF_NOTES; i++) {
        styles += `
            ${Note}:nth-child(${i}) { 
                left: ${(i - 1) * (NOTE_WIDTH + NOTE_MARGIN) + LOADER_PADDING}px;
                animation: slide ${ANIM_DURATION}s infinite ${(i - 1) * ANIM_DELAY}s;
            }
        `
    }

    return css`${styles}`;
}

const Loader = styled.div`
    background: transparent;
    position: relative;
    height: ${LOADER_HEIGHT}px;
    width: ${(NUMBER_OF_NOTES * (NOTE_WIDTH + NOTE_MARGIN) + (LOADER_PADDING * 2) - NOTE_MARGIN)}px;
    overflow: hidden;
    box-shadow: -1px 12px 14px -15px #0000002b;
    
    &:after {
        content: '';
        width: 100%;
        height: ${LOADER_HEIGHT * .77}px;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(242,242,242) 0%, rgba(242,242,242,0) 100%);
    }
    
	${notes()};
`;

const Text = styled.div`
	margin-top: 1rem;
`;

interface Props {
    text?: string;
}

const GlobalLoader: React.FC<Props> = ({ text = 'Loading...' }) => {
    const renderNotes = () => {
        const notes: any = [];

        for (let i = 0; i < NUMBER_OF_NOTES; i++) {
            notes.push(<Note key={i}/>)
        }

        return notes;
    }

    return (
        <Container>
            <Loader>
                {renderNotes()}
            </Loader>
            <Text>{text}</Text>
        </Container>
    );
};

export default GlobalLoader;
